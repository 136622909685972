import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonSpinner } from "@ionic/react";
import { hideKeyboardOnKeyPressEnter } from "@src/app/common/ionInputHooks/hideKeyboardOnKeyPress";
import { WorkedBreakConfirmation } from "@src/app/hcpShifts/components/workedBreakConfirmation";
import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "@src/app/hcpShifts/constants";
import {
  SignatureContainer,
  SignatureContainerRef,
} from "@src/app/shiftSignature/signatureContainer";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { WorkedBreakAuthorizationParams } from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakRequestCreationPage";
import { TimeRange } from "@src/lib/interface/src";
import { capitalizeFirstLetterOfEveryWord } from "@src/lib/utils/src";
import { useRef, useState } from "react";

export interface SignatureConfirmationProps {
  onSubmit: (file: SelectedFile, facilityEmployeeName: string) => void;
  lunchInOutTimeRange: TimeRange;
  isUploadingImage: boolean;
  isShiftTimeEditOpen: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  workedBreakAuthorization?: WorkedBreakAuthorizationParams;
  onStepChange: (currentStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => void;
  onOpenTimeEdit: () => void;
}

export function SignatureConfirmation(props: SignatureConfirmationProps) {
  const {
    onSubmit,
    lunchInOutTimeRange,
    isUploadingImage,
    isShiftTimeEditOpen,
    isSolveUnpaidBreaksEnabled,
    workedBreakAuthorization,
    onStepChange,
    onOpenTimeEdit,
  } = props;

  const signaturePad = useRef<SignatureContainerRef>(null);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [signature, setSignature] = useState<string>("");
  const [facilityEmployeeName, setFacilityEmployeeName] = useState<string>("");
  const [isWorkedBreakStatementConfirmed, setIsWorkedBreakStatementConfirmed] = useState(false);

  const [currentStep, setCurrentStep] = useState<DIGITAL_TIMESHEET_SIGNATURE_STEPS>(
    DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE
  );

  function clearSignature() {
    signaturePad?.current?.clearSignature();
    setDisableSubmit(true);
  }

  const goToStep = (step: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => {
    setCurrentStep(step);
    onStepChange(step);
  };

  return (
    <>
      <IonContent>
        {currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE && (
          <div id="facilityEmpNameSection">
            <div className="timesheet-summary-text signature-confirmation-prompt">
              <span>Please ask a workplace employee to</span>
              <br />
              <span>verify your times and sign</span>
            </div>
            <IonItem lines="none" className="signature-employee-division">
              <IonLabel position="fixed" className="signature-employee-name-label">
                Name
              </IonLabel>
              <IonInput
                className="signature-employee-name signature-employee-input"
                placeholder="Workplace Employee Name"
                value={facilityEmployeeName}
                type="text"
                onKeyPress={hideKeyboardOnKeyPressEnter}
                onIonChange={(e) => {
                  setFacilityEmployeeName(
                    isDefined(e.detail.value)
                      ? capitalizeFirstLetterOfEveryWord(e.detail.value)
                      : ""
                  );
                }}
                clearInput
              />
            </IonItem>
            <IonButton
              fill="solid"
              shape="round"
              color="primary"
              disabled={
                !/[a-zA-Z]/.test(facilityEmployeeName) ||
                (isSolveUnpaidBreaksEnabled && isShiftTimeEditOpen)
              }
              className="ion-margin sign-button sign-confirmation-continue-button"
              onClick={() => goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE)}
            >
              Continue
            </IonButton>
          </div>
        )}
        {workedBreakAuthorization &&
          currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION && (
            <WorkedBreakConfirmation
              isTimeEditOpen={isShiftTimeEditOpen}
              lunchInOutTimeRange={lunchInOutTimeRange}
              workedBreakAuthorization={workedBreakAuthorization}
              onEditTimesClick={onOpenTimeEdit}
              onConfirmClick={() => {
                setIsWorkedBreakStatementConfirmed(true);
                setCurrentStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE);
              }}
            />
          )}
        {(currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE ||
          (currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION &&
            !isDefined(workedBreakAuthorization))) && (
          <div id="facilityEmpSignSection">
            <div className="signature-pad-name-item">
              <p className="signature-pad-name">
                {facilityEmployeeName}
                <span>, please sign below.</span>
              </p>
            </div>
            <div className="signature-pad-canvas" aria-label="Signature Pad">
              <SignatureContainer
                ref={signaturePad}
                onChange={(updatedSignature: string) => {
                  setSignature(updatedSignature);
                  setDisableSubmit(!updatedSignature || updatedSignature === "");
                }}
                onStrokeStarted={() => {
                  if (workedBreakAuthorization && !isWorkedBreakStatementConfirmed) {
                    goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION);
                  }
                }}
              />
            </div>
            <div className="sign-button-container">
              <IonButton
                fill="outline"
                shape="round"
                className="ion-margin sign-button"
                onClick={() => {
                  clearSignature();
                  setIsWorkedBreakStatementConfirmed(false);
                  goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE);
                }}
              >
                Back
              </IonButton>
              <IonButton
                fill="outline"
                shape="round"
                className="ion-margin sign-button"
                onClick={clearSignature}
              >
                Clear
              </IonButton>
              <IonButton
                shape="round"
                color="primary"
                className="ion-margin sign-button"
                onClick={() => {
                  const file = {
                    file: signature,
                    type: "png",
                  };
                  onSubmit(file, facilityEmployeeName);
                }}
                disabled={disableSubmit || isUploadingImage || isShiftTimeEditOpen}
              >
                <IonSpinner hidden={!isUploadingImage} slot="start" name="crescent" />
                {!isUploadingImage ? "Submit" : "Submitting"}
              </IonButton>
            </div>
          </div>
        )}
      </IonContent>
    </>
  );
}
