import { Capacitor } from "@capacitor/core";
import { NFC } from "@ionic-native/nfc";
import { DeviceNFCCapabilityForShift } from "@src/lib/interface/src/lib/shift";

export async function deviceNFCCapabilityForShift() {
  try {
    if (!Capacitor.isNativePlatform() || !NFC) {
      return DeviceNFCCapabilityForShift.NO_NFC;
    }

    await NFC.enabled();
    return DeviceNFCCapabilityForShift.NFC_ENABLED;
  } catch (error) {
    console.warn("Error in checking NFC capabilities:", error);
    return error;
  }
}
